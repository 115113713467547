// 
.cover {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (orientation:landscape) {
        height: 100vh;
    }

    @media (orientation:portrait) {
        height: 100vh;
    }

    .top {
        display: flex;
        width: 100%;
        height: 98%;
        overflow: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        @media (orientation:landscape) {
            flex-direction: row;
        }

        @media (orientation:portrait) {
            flex-direction: column;
        }

        .top-left {
            background-color: white;
            display: flex;
            flex-direction: column;
            scrollbar-width: none;
            -ms-overflow-style: none;
            justify-content: space-between;

            @media (orientation:landscape) {
                width: 33%;
                height: 100%;
            }

            @media (orientation:portrait) {
                width: 100%;
                max-height: 33%;
            }

            .header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;

                @media (max-width: 750px) {
                    margin-bottom: 10px;
                }

                &__text {
                    margin: 10px 0px 10px 0px;
                    color: #000;
                    text-align: center;
                    font-style: normal;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 14px;

                    @media (max-width: 750px) {
                        font-size: 12px;
                    }

                    @media (max-width: 500px) {
                        font-size: 20px;
                    }
                }

                &__row {
                    background-color: black;
                    height: 2px;
                    margin: auto;
                    width: 95%;
                }
            }

            .top {
                display: flex;
                flex-direction: column;
                overflow: auto;
                scrollbar-width: none;
                -ms-overflow-style: none;

                .body {
                    display: flex;
                    flex-direction: column;

                    .block-cover {
                        display: flex;
                        flex-wrap: wrap;
                        margin: auto;
                        width: 100%;

                        .block-type-1 {
                            background-color: #000;
                            width: 95%;
                            color: white;
                            margin: auto;
                            margin-bottom: 10px;

                            .block-header {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                padding: 2%;

                                .cus-name {
                                    color: #FFF;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 700;
                                    text-transform: uppercase;
                                    font-family: 'Oswald', sans-serif;
                                }

                                .cus-phone {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;

                                    &__icon {
                                        display: flex;
                                        align-items: center;

                                        .img {
                                            height: 15px;
                                        }
                                    }

                                    &__number {
                                        font-size: 15px;
                                    }
                                }

                            }

                            .block-body {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                height: 20px;

                                .left {
                                    width: max-content;
                                    display: flex;
                                    justify-content: left;
                                    align-items: center;
                                    margin-left: 5px;
                                    background-color: #000000;


                                    .text {
                                        // display: none;
                                        color: #0147C6;
                                        font-family: 'Be Vietnam Pro', sans-serif;
                                        font-size: 13px;
                                        font-weight: 600;
                                        color: rgb(255, 255, 255);
                                    }
                                }

                                .right {
                                    display: flex;
                                    justify-content: right;
                                    align-items: center;
                                    width: max-content;
                                    background-color: #000;

                                    .cus-time {
                                        color: #ffffff;
                                        background-color: #11B14B;
                                        border-bottom: 1px solid #11B14B;
                                        border-top: 1px solid #11B14B;
                                        width: max-content;
                                        height: 80%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .text {
                                            font-size: 14px;
                                            margin-left: 2px;
                                            margin-right: 2px;
                                            font-weight: 700;

                                        }
                                    }

                                    .cus-type {
                                        color: #11B14B;
                                        background-color: white;
                                        border-bottom: 1px solid #11B14B;
                                        border-top: 1px solid #11B14B;
                                        width: max-content;
                                        height: 80%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .text {
                                            font-size: 12px;
                                            margin-left: 2px;
                                            margin-right: 2px;

                                        }
                                    }
                                }

                                .right-2 {
                                    display: flex;
                                    justify-content: right;
                                    align-items: center;
                                    width: max-content;
                                    background-color: #000000;


                                    .cus-time {
                                        color: #ffffff;
                                        background-color: #FE4E40;
                                        border-bottom: 1px solid #FE4E40;
                                        border-top: 1px solid #FE4E40;
                                        width: max-content;
                                        height: 80%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .text {
                                            font-size: 14px;
                                            margin-left: 2px;
                                            margin-right: 2px;
                                            font-weight: 700;

                                        }
                                    }

                                    .cus-type {
                                        color: #FE4E40;
                                        background-color: white;
                                        border-bottom: 1px solid #FE4E40;
                                        border-top: 1px solid #FE4E40;
                                        width: max-content;
                                        height: 80%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .text {
                                            font-size: 12px;
                                            margin-left: 2px;
                                            margin-right: 2px;

                                        }
                                    }
                                }
                            }

                            .block-footer {
                                display: flex;
                                justify-content: right;
                                align-items: center;

                                .early {
                                    color: rgb(255, 255, 255)000;
                                    font-family: 'Be Vietnam Pro', sans-serif;
                                    font-size: 10px;
                                    padding: 2%;
                                    font-weight: 400;
                                    width: max-content;
                                    height: 12px;
                                }

                            }
                        }
                    }
                }
                .body-null {
                    margin: 10px 0px 10px 0px;
    
                    .text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
    
                    
                }
            }

            .top::-webkit-scrollbar {
                width: 0 !important;
                display: none;
            }

            

            .bottom {

                .text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 10px 0px 10px 0px;

                    @media (orientation:landscape) {
                        display: none;

                    }
                }

                @media (orientation:portrait) {
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                }
            }

            .hidden {
                @media (orientation:landscape) {
                    display: none;
                }

                @media (orientation:portrait) {}
            }
        }

        .left::-webkit-scrollbar {
            width: 0 !important;
            display: none;
        }

        .top-right {
            display: flex;
            flex-direction: column;
            scrollbar-width: none;
            -ms-overflow-style: none;
            justify-content: space-between;

            @media (orientation:landscape) {
                width: 33%;
                height: 100%;
                background-color: #000000;
            }

            @media (orientation:portrait) {
                width: 100%;
                max-height: 33%;
                background-color: rgb(255, 255, 255);
            }

            .header {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;

                @media (max-width: 750px) {
                    margin-bottom: 10px;
                }

                &__text {
                    margin: 10px 0px 10px 0px;
                    text-align: center;
                    font-style: normal;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 14px;

                    @media (max-width: 750px) {
                        font-size: 12px;
                    }

                    @media (max-width: 500px) {
                        font-size: 20px;
                    }

                    @media (orientation:landscape) {
                        background-color: #000000;
                        color: rgb(255, 255, 255);
                    }

                    @media (orientation:portrait) {
                        background-color: rgb(255, 255, 255);
                        color: #000000;

                    }
                }

                &__row {
                    height: 2px;
                    margin: auto;
                    width: 95%;

                    @media (orientation:landscape) {
                        background-color: #ffffff;
                    }

                    @media (orientation:portrait) {
                        background-color: #000000;
                    }
                }
            }

            .top {
                display: flex;
                flex-direction: column;
                overflow: auto;
                scrollbar-width: none;
                -ms-overflow-style: none;

                @media (orientation:landscape) {
                    background-color: #000000;
                }

                @media (orientation:portrait) {
                    background-color: #ffffff;
                }

                .body {
                    display: flex;
                    flex-direction: column;

                    .block-cover {
                        display: flex;
                        flex-wrap: wrap;
                        margin: auto;
                        width: 100%;

                        .block-type-2 {
                            width: 95%;
                            margin: auto;
                            margin-bottom: 10px;

                            @media (orientation:landscape) {
                                background-color: #ffffff;
                                color: rgb(0, 0, 0);
                            }

                            @media (orientation:portrait) {
                                background-color: #000000;
                                color: rgb(255, 255, 255);
                            }

                            .block-header {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                padding: 2%;

                                .cus-name {
                                    color: rgb(0, 0, 0);
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 700;
                                    text-transform: uppercase;
                                    font-family: 'Oswald', sans-serif;

                                    @media (orientation:landscape) {
                                        color: rgb(0, 0, 0);
                                    }

                                    @media (orientation:portrait) {
                                        color: rgb(255, 255, 255);
                                    }
                                }

                                .cus-phone {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;

                                    &__icon {
                                        display: flex;
                                        align-items: center;

                                        .img-col {
                                            height: 15px;


                                            @media (orientation:portrait) {
                                                display: none;
                                            }
                                        }

                                        .img-row {
                                            height: 15px;

                                            @media (orientation:landscape) {
                                                display: none;

                                            }
                                        }
                                    }

                                    &__number {
                                        font-size: 15px;
                                    }
                                }

                            }

                            .block-body {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                height: 20px;

                                .left {
                                    width: max-content;
                                    display: flex;
                                    justify-content: left;
                                    align-items: center;
                                    margin-left: 5px;

                                    @media (orientation:landscape) {
                                        background-color: #ffffff;
                                    }

                                    @media (orientation:portrait) {
                                        background-color: #000000;
                                    }

                                    .text {
                                        // display: none;
                                        color: #0147C6;
                                        font-family: 'Be Vietnam Pro', sans-serif;
                                        font-size: 13px;
                                        font-weight: 600;

                                        @media (orientation:landscape) {
                                            color: #0147C6;
                                        }

                                        @media (orientation:portrait) {
                                            color: rgb(255, 255, 255);
                                        }
                                    }
                                }

                                .right {
                                    display: flex;
                                    justify-content: right;
                                    align-items: center;
                                    background-color: #ffffff;
                                    width: max-content;

                                    @media (orientation:landscape) {
                                        background-color: #ffffff;
                                    }

                                    @media (orientation:portrait) {
                                        background-color: #000000;
                                    }

                                    .cus-time {
                                        color: #ffffff;
                                        background-color: #11B14B;
                                        border-bottom: 1px solid #11B14B;
                                        border-top: 1px solid #11B14B;
                                        width: max-content;
                                        height: 80%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .text {
                                            font-size: 14px;
                                            margin-left: 2px;
                                            margin-right: 2px;
                                            font-weight: 700;

                                        }
                                    }

                                    .cus-type {
                                        color: #11B14B;
                                        background-color: white;
                                        border-bottom: 1px solid #11B14B;
                                        border-top: 1px solid #11B14B;
                                        width: max-content;
                                        height: 80%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .text {
                                            font-size: 12px;
                                            margin-left: 2px;
                                            margin-right: 2px;

                                        }
                                    }
                                }

                                .right-2 {
                                    display: flex;
                                    justify-content: right;
                                    align-items: center;
                                    background-color: #ffffff;
                                    width: max-content;

                                    @media (orientation:landscape) {
                                        background-color: #ffffff;
                                    }

                                    @media (orientation:portrait) {
                                        background-color: #000000;
                                    }

                                    .cus-time {
                                        color: #ffffff;
                                        background-color: #FE4E40;
                                        border-bottom: 1px solid #FE4E40;
                                        border-top: 1px solid #FE4E40;
                                        width: max-content;
                                        height: 80%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .text {
                                            font-size: 14px;
                                            margin-left: 2px;
                                            margin-right: 2px;
                                            font-weight: 700;

                                        }
                                    }

                                    .cus-type {
                                        color: #FE4E40;
                                        background-color: white;
                                        border-bottom: 1px solid #FE4E40;
                                        border-top: 1px solid #FE4E40;
                                        width: max-content;
                                        height: 80%;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .text {
                                            font-size: 12px;
                                            margin-left: 2px;
                                            margin-right: 2px;

                                        }
                                    }
                                }
                            }

                            .block-footer {
                                display: flex;
                                justify-content: right;
                                align-items: center;

                                .early {
                                    color: rgb(0, 0, 0)000;
                                    font-family: 'Be Vietnam Pro', sans-serif;
                                    font-size: 10px;
                                    padding: 2%;
                                    font-weight: 400;
                                    width: max-content;
                                    height: 12px;

                                }

                            }
                        }
                    }
                }

                .body-null {
                    margin: 10px 0px 10px 0px;

                    .text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    @media (orientation:landscape) {
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        color: #ffffff;
                    }

                    @media (orientation:portrait) {
                        align-items: center;
                        justify-content: center;
                        display: flex;
                    }
                }
            }

            .top::-webkit-scrollbar {
                width: 0 !important;
                display: none;
            }


        }

        .right::-webkit-scrollbar {
            width: 0 !important;
            display: none;
        }
        .null{
            @media (orientation:portrait) {
                height: max-content;
            }
        }
    }

    .top::-webkit-scrollbar {
        width: 0 !important;
        display: none;
    }

    .bottom {
        height: auto;

        .text-container {
            overflow: hidden;
            background-color: #15397F;

            .scrolling-text {
                display: flex;
                align-items: center;
                justify-content: center;
                width: max-content;
                margin: 0px !important;
                color: #ffffff;
                font-family: Be Vietnam;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                animation: scrollText 30s linear infinite;
            }

            @keyframes scrollText {
                0% {
                    transform: translateX(100%);
                }

                100% {
                    transform: translateX(-100%);
                }
            }
        }
    }
}